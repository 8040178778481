import { initializeApp } from 'firebase/app';

import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc, getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCeQHtd_Y_ZRg7Ir9ciwcwjoO2siexL-8o',
  authDomain: 'talentcomp-dbdc7.firebaseapp.com',
  projectId: 'talentcomp-dbdc7',
  storageBucket: 'talentcomp-dbdc7.appspot.com',
  messagingSenderId: '796573917035',
  appId: '1:796573917035:web:e62faf26bf902b957b362e',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;

export const provider = new GoogleAuthProvider();

const db = getFirestore(app);
export { db };

/*****************************************************************/
/*    The below functions are used to store User Metadata       */
/*****************************************************************/
export async function getUserData(userId) {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      console.error('No such user document!');
      return null;
    }
  } catch (error) {
    const errorMessage = error.message;
    console.log('Error getting user data', errorMessage);
    return null;
  }
}

export async function createNewUserData(data, userId) {
  try {
    // let payload = {
    //   ...data,
    //   username: 'User 215612',
    //   profilePic:
    //     'http://m.gettywallpapers.com/wp-content/uploads/2023/06/Pfp-Cool.jpg',
    //   offerLetters: [],
    // };

    const userDataDocRef = doc(db, 'users', userId);
    const userDataDocSnap = await getDoc(userDataDocRef);

    if (!userDataDocSnap.exists()) {
      // Track does not exist, add it
      await setDoc(userDataDocRef, data);
    }

    return true;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);

    return false;
  }
}
