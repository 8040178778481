import {
  startStreaming,
  updateStreamingResponse,
  endStreaming,
  setLoading,
  resetQuery,
} from '../features/query/QuerySlice';

const secretKey = process.env.REACT_APP_OPENAI_API_KEY;
const assistantId = process.env.REACT_APP_OPENAI_ASSISTANT_ID;

const OpenAI = require('openai');
const openai = new OpenAI({
  apiKey: secretKey,
  dangerouslyAllowBrowser: true,
});

// Create a thread
let thread = await openai.beta.threads.create();

export async function createNewThread() {
  thread = await openai.beta.threads.create();
}

export async function sendQuery(dispatch, user, query, context) {
  dispatch(resetQuery());
  dispatch(setLoading(true));
  dispatch(startStreaming());

  try {
    // Check for active runs
    const runs = await openai.beta.threads.runs.list(thread.id);
    const activeRun = runs.data.find((run) => run.status === 'active');

    if (activeRun) {
      console.warn(
        'There is already an active run for this thread. Please wait until it completes.'
      );
      dispatch(setLoading(false));
      dispatch(endStreaming());
      return;
    }

    // Create a new message from the user in the thread
    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: context
        ? `This is the context in which you should look for the answers (files users have questions about):\n${context}\nThis is a user's question:\n ${query}`
        : query,
    });

    // Send the thread to the assistant by creating a thread run
    const stream = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistantId,
      stream: true,
    });

    for await (const event of stream) {
      if (event.event === 'thread.message.delta') {
        const content = event.data.delta.content[0].text.value;
        if (content !== undefined && content !== '') {
          dispatch(updateStreamingResponse(content));
        }
      }
    }
    dispatch(endStreaming());
  } catch (error) {
    console.error('Error during query:', error);
    dispatch(setLoading(false));
    dispatch(endStreaming());
  }
}
