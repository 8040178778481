import { createSlice } from '@reduxjs/toolkit';

export const query = createSlice({
  name: 'query',
  initialState: {
    values: [],
    value: '',
    submitted: false,
    results: [],
    result: '',
    placeHolder: 'Type message',
    loading: false,
    streaming: false,
    streamedResponse: '',
  },
  reducers: {
    updateQuery: (state, newQuery) => {
      state.value = newQuery.payload;
    },
    submitQuery: (state) => {
      if (state.value !== '') {
        state.values.push(state.value);
        state.submitted = true;
      }
    },
    setLoading: (state, value) => {
      state.loading = value;
    },
    setQueryResult: (state, result) => {
      if (result.payload !== undefined) {
        state.results.push(result.payload);
        state.result = result.payload;
      }
      state.placeHolder = 'Follow up question';
      state.submitted = false;
      state.value = '';
    },
    clearQuery: (state) => {
      state.values = [];
      state.value = '';
      state.submitted = false;
      state.results = [];
      state.result = '';
      state.placeHolder = 'Type message';
    },
    startStreaming: (state) => {
      state.streaming = true;
      state.streamedResponse = '';
    },
    updateStreamingResponse: (state, responsePart) => {
      if (responsePart.payload !== undefined) {
        state.streamedResponse += responsePart.payload;
      }
    },
    endStreaming: (state) => {
      if (
        state.streamedResponse !== undefined &&
        state.streamedResponse !== ''
      ) {
        state.results.push(state.streamedResponse);
        state.result = state.streamedResponse;
      }
      state.streaming = false;
      state.streamedResponse = '';
    },
    resetQuery: (state) => {
      state.value = '';
    },
  },
});

export const {
  updateQuery,
  submitQuery,
  clearQuery,
  setQueryResult,
  setLoading,
  startStreaming,
  updateStreamingResponse,
  endStreaming,
  resetQuery,
} = query.actions;

export default query.reducer;
