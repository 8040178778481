import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const FilesInSearch = ({ files, removeFile }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflowX: 'auto',
        height: '100%',
        backgroundColor: 'transparent',
        px: 3.5,
      }}
    >
      {files.map((file, index) => {
        const fileName = file.file.name;
        const lastDotIndex = fileName.lastIndexOf('.');
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex + 1).toUpperCase();

        return (
          <Box
            key={index}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: '#d0d0d0',
              paddingY: '8px',
              paddingLeft: '0.5rem',
              paddingRight: '2rem',
              borderRadius: '8px',
              marginRight: '8px',
              marginBottom: '0.5rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'primary.main',
                borderRadius: '4px',
                width: '36px',
                height: '36px',
                marginRight: '8px',
              }}
            >
              <InsertDriveFileIcon sx={{ color: '#fff' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                color='black'
                sx={{
                  opacity: '70%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 800,
                  fontSize: { xs: 'caption', sm: 'body2' },
                }}
              >
                {name}
              </Typography>
              <Typography
                color='GrayText'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: { xs: '0.5rem', sm: '0.75rem' },
                }}
              >
                {extension}
              </Typography>
            </Box>
            <IconButton
              size='small'
              onClick={() => removeFile(file.file.name)}
              sx={{
                position: 'absolute',
                top: '4px',
                right: '4px',
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default FilesInSearch;
