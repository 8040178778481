import React, { useState, useEffect, useContext } from 'react';
import {
  auth,
  getUserData,
  createNewUserData,
  provider,
} from '../api/firebase';
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import { getErrorMessage } from '../helpers/utils';

export const UserContext = React.createContext(null);

export function UserProvider({ children }) {
  const [loading, setLoading] = useState(true);
  // ROLES null, user, admin
  const [userData, setUserData] = useState(null);
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        try {
          const userData = await getUserData(user.uid);
          setUserData(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserData(null);
        }
      } else {
        // User is signed out
        setUserData(null);
        if (!window.location.href.includes('sign')) {
          window.location.replace('/signin');
        }
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const updateCurrentUserData = (newUserData) => {
    if (newUserData) setUserData(newUserData);
  };

  const handleGoogleAuth = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const newUserData = {
        name: user?.displayName,
        email: user?.email,
        role: 'user',
        profileImage: user?.photoURL,
      };
      const res = await createNewUserData(newUserData, user.uid);
      if (res) {
        updateCurrentUserData(newUserData);
      }
      setLoading(false);
      return res;
    } catch (error) {
      // Sign up failed
      const errorMessage = getErrorMessage(error.code);
      setAuthError(errorMessage);
      setLoading(false);
      return false;
      // sendMessage('error', error.message);
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUserData(null);
      window.location.replace('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        signOut,
        updateCurrentUserData,
        handleGoogleAuth,
        loading,
        setLoading,
        authError,
        setAuthError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export const useUser = () => {
  return useContext(UserContext);
};
