import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuery, submitQuery } from '../../features/query/QuerySlice';
import { useFile } from '../../context/FileContext';
import { hexToRgba } from '../../helpers/utils';

import { sendQuery } from '../../api/API';
import { validateQuery } from '../../helpers/utils';

const InfoCard = ({ cards, setIsBottom }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { assistantContext } = useFile();

  const handleCardClick = async (e) => {
    setIsBottom(true);

    const validQuery = validateQuery(e.nativeEvent.target.innerText);

    if (validQuery) {
      dispatch(updateQuery(e.nativeEvent.target.innerText));
      dispatch(submitQuery());

      await sendQuery(
        dispatch,
        user,
        e.nativeEvent.target.innerText,
        assistantContext
      );
    }
  };

  return (
    <Box width={{ xs: '100%', lg: '25%' }}>
      <CssBaseline />
      <Box
        display='flex'
        flexWrap='wrap'
        justifyContent={isSmallScreen ? 'center' : 'space-around'}
        alignItems='center'
        gap={2}
        sx={{
          flexDirection: isSmallScreen ? 'column' : 'row',
        }}
      >
        {cards.map((card, index) => (
          <Card
            key={index}
            sx={{
              height: '9rem',
              flex: '1 1 100%',
              width: '100%',
              backgroundColor: card.color,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: hexToRgba(card.color, 0.8),
                cursor: 'pointer',
              },
              borderRadius: '15px',
              boxSizing: 'border-box',
              padding: '16px',
            }}
            onClick={handleCardClick}
          >
            <CardContent
              sx={{
                textAlign: 'center',
                width: '100%',
                height: '100%',
                padding: '0 !important',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                fontSize={'1.2rem'}
                fontWeight='Bold'
                color='textPrimary'
                marginBottom={'0.5rem'}
                marginTop={'0.5rem'}
              >
                {card.text}
              </Typography>
              <Typography fontSize={'1rem'} color='textPrimary'>
                {card.subtitle}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default InfoCard;
