import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuery, submitQuery } from '../../features/query/QuerySlice';
import { sendQuery } from '../../api/API';
import { validateQuery } from '../../helpers/utils';
import { useFile } from '../../context/FileContext';
import UploadFileButton from '../dropzone/UploadFIleButton';
import FilesInSearch from './FilesInSearch';

const CustomInput = forwardRef(
  (
    {
      value,
      onChange,
      onKeyDown,
      handleSubmit,
      files,
      removeFile,
      placeholder,
      disabled,
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          width: '100%',
          borderRadius: '20px',
          border: '1px solid #282828',
          backgroundColor: '#fff',
          padding: '8px',
          paddingRight: '16px',
        }}
      >
        <FilesInSearch files={files} removeFile={removeFile} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UploadFileButton />
          <input
            type='text'
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={{
              flex: 1,
              border: 'none',
              outline: 'none',
              backgroundColor: 'transparent',
              color: '#282828',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
            placeholder={placeholder}
            ref={ref}
          />
          <SendIcon
            sx={{
              color: disabled ? '#6a6a6a' : '#282828',
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: !disabled && 'pointer',
              },
            }}
            disabled={disabled}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    );
  }
);

const SearchBar = ({ setIsBottom }) => {
  const value = useSelector((state) => state.query.value);
  const placeHolder = useSelector((state) => state.query.placeHolder);
  const user = useSelector((state) => state.user);

  const { assistantContext, files, removeFile, onSendFiles } = useFile();

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    dispatch(updateQuery(e.target.value));
  };

  const validQuery = validateQuery(value);

  const handleSubmit = async (e) => {
    if (validQuery) {
      setIsBottom(true);
      dispatch(submitQuery());
      if (files.length > 0) {
        onSendFiles();
      }
      await sendQuery(dispatch, user, value, assistantContext);
    }
  };

  const handleCheckSubmit = (e) => {
    if (e.key === 'Enter' || e.key === 'NumpadEnter') {
      handleSubmit(e);
    }
  };

  return (
    <Box>
      <CssBaseline />
      <TextField
        variant='outlined'
        placeholder={placeHolder}
        value={value}
        onChange={handleInputChange}
        disabled={!validQuery}
        onKeyDown={handleCheckSubmit}
        sx={{
          width: '80vw !important',
          '& .MuiInputBase-input': {
            display: 'none', // Hide the default input element
          },
          '& .MuiInputBase-root': {
            backgroundColor: '#fff',
            color: '#282828',
            borderRadius: '20px',
            border: 'none',
            textAlign: 'left',
            marginRight: '3%',
            paddingBottom: 0,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#818181', // Change the border color when focused
            },
        }}
        InputProps={{
          inputComponent: CustomInput,
          inputProps: {
            value,
            onChange: handleInputChange,
            onKeyDown: handleCheckSubmit,
            handleSubmit,
            files,
            removeFile,
            placeholder: placeHolder,
            disabled: !validQuery,
          },
        }}
      />
    </Box>
  );
};

export default SearchBar;
