export const infoCards = {
  card1: {
    cards: [
      {
        text: 'What do stock options mean?',
        subtitle: 'A brief explanation of stock options',
        color: '#EE6123',
      },
    ],
  },
  card2: {
    cards: [
      {
        text: 'What does vesting schedule mean?',
        subtitle: 'An explanation of vesting schedules.',
        color: '#92C9B1',
      },
    ],
  },
  card3: {
    cards: [
      {
        text: 'Advice on equity',
        subtitle: 'Watch-outs, exercise, and taxes advice.',
        color: '#F5D547',
      },
    ],
  },
  card4: {
    cards: [
      {
        text: 'Negotiation strategy and script',
        subtitle: 'Strategies and scripts for successful negotiation.',
        color: '#3083DC',
      },
    ],
  },
  card5: {
    cards: [
      {
        text: 'Full Walkthrough of Offer Terms',
        subtitle:
          'An in-depth guide to understanding your startup offer terms.',
        color: '#69B578',
      },
    ],
  },
};
