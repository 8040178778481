import React from 'react';
import { Box, Typography } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useFile } from '../../context/FileContext';

export default function FileCard({ index }) {
  const { sentFiles } = useFile();
  const filesToShow = sentFiles.filter((file) => file.index === index);

  return (
    filesToShow.length > 0 && (
      <Box sx={{ display: 'flex', flexDirection: 'column', my: 0.5 }}>
        {filesToShow.map((file, idx) => {
          const fileName = file.file.file.name;
          const lastDotIndex = fileName.lastIndexOf('.');
          const name = fileName.substring(0, lastDotIndex);
          const extension = fileName.substring(lastDotIndex + 1).toUpperCase();

          return (
            <Box
              key={idx}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: 'transparent',
                paddingY: '8px',
                paddingLeft: '0.5rem',
                paddingRight: '2rem',
                borderRadius: '8px',
                marginRight: '8px',
                marginBottom: '0.5rem',
                border: '1px solid black',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'primary.main',
                  borderRadius: '4px',
                  width: '36px',
                  height: '36px',
                  marginRight: '8px',
                }}
              >
                <InsertDriveFileIcon sx={{ color: '#fff' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  color='black'
                  sx={{
                    opacity: '70%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 800,
                    fontSize: { xs: 'caption', sm: 'body2' },
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  color='GrayText'
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: { xs: '0.5rem', sm: '0.75rem' },
                  }}
                >
                  {extension}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    )
  );
}
