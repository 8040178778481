import React from 'react';
import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// import { updateUserData } from '../features/user/UserSlice';
// import { useDispatch } from 'react-redux';
// import { NotificationContext } from '../context/NotificationContext';

import GoogleIcon from '@mui/icons-material/Google';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, createNewUserData } from '../api/firebase';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../helpers/utils';
import { useUser } from '../context/UserContext';
import authImage from '../assets/auth-side.png';

export default function SignUp() {
  const navigate = useNavigate();
  const {
    handleGoogleAuth,
    authError,
    setAuthError,
    loading,
    updateCurrentUserData,
    setLoading,
  } = useUser();
  // const dispatch = useDispatch();
  // const { sendMessage } = useContext(NotificationContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.get('email'),
        data.get('password')
      );
      // Sign up succeeded (auth data)
      const user = userCredential.user;

      const newUserData = {
        name: `${data.get('firstName')} ${data.get('lastName')}`,
        email: data.get('email'),
        role: 'user',
        profileImage: null,
      };
      const res = await createNewUserData(newUserData, user.uid);
      if (res) {
        // dispatch(updateUserData(userData));
        updateCurrentUserData(newUserData);
        navigate('/');
      }
      setLoading(false);
    } catch (error) {
      // Sign up failed
      const errorMessage = getErrorMessage(error.code);
      setAuthError(errorMessage);
      setLoading(false);
      // sendMessage('error', error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    const res = await handleGoogleAuth();
    if (res) navigate('/');
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${authImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Box}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Container component='main' maxWidth='xs'>
          <Box
            sx={{
              xs: { mt: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign up
            </Typography>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete='given-name'
                    name='firstName'
                    required
                    fullWidth
                    id='firstName'
                    label='First Name'
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id='lastName'
                    label='Last Name'
                    name='lastName'
                    autoComplete='family-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='new-password'
                  />
                </Grid>
              </Grid>
              <Typography color='error'>{authError}</Typography>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign Up'}
              </Button>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                sx={{ mt: 2, mb: 1 }}
                onClick={handleGoogleSignUp}
                startIcon={<GoogleIcon />}
                disabled={loading}
              >
                Sign up with Google
              </Button>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Link href='/signin' variant='body2'>
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Typography
              variant='caption'
              sx={{ mt: 5, display: { xs: 'none', sm: 'block' } }}
            >
              Image by Makers Company
            </Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}
