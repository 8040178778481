import { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../../context/UserContext';
import { ReactComponent as DefaultAvatar } from '../../assets/avatar.svg';
import Logout from '@mui/icons-material/Logout';

export default function Navbar() {
  const { userData, signOut } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleMenuClose();
    signOut();
  };

  return (
    <AppBar position='static' color='primary'>
      <Toolbar>
        <Typography
          variant={isMobile ? 'subtitle1' : 'h6'}
          component='div'
          sx={{ flexGrow: 1 }}
        >
          OfferX AI
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: isMobile ? 0 : 4,
          }}
        >
          <Typography variant={isMobile ? 'body2' : 'body1'}>
            {userData?.name}
          </Typography>
          <IconButton onClick={handleMenuOpen}>
            <Avatar>
              {userData?.profileImage ? (
                <img
                  src={userData.profileImage}
                  alt='Profile'
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                <DefaultAvatar />
              )}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem disabled>{userData?.email}</MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>
              <Logout sx={{ mr: 1 }} /> Sign out
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
