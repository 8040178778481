import { configureStore } from '@reduxjs/toolkit'
import queryReducer from '../features/query/QuerySlice';
import userReducer from '../features/user/UserSlice';

// automatically sets up the store with good default settings
export default configureStore({
  reducer: {
    query: queryReducer,
    user: userReducer
  },
})