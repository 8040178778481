import { createSlice } from '@reduxjs/toolkit'

export const user = createSlice({
  // Name of slice
  name: 'user',
  // Initial state of slice
  initialState: {
    loggedIn: true,
    firstName: '',
    lastName: '',
    email: '',
    username: 'OfferX AI',
    profilePic: '',
    offerLetters: ["Startup XYZ", "Startup ABC", "Startup SSS"]
  },
  // Functions how slice can be updated
  reducers: {
    updateUserData: (state, action) => {
      const newData = action.payload;
      Object.assign(state, newData);
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateUserData } = user.actions

export default user.reducer