import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Snackbar,
  IconButton,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useSpring, animated } from 'react-spring';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const QueryResultCard = (props) => {
  const queryResult = props.queryResult;

  const [isCopied, setIsCopied] = React.useState(false);

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(100%)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  });

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  const markDownReturn = (
    <Markdown remarkPlugins={[remarkGfm]}>{queryResult}</Markdown>
  );

  return (
    <animated.div style={animationProps}>
      <Card
        sx={{
          maxWidth: { xs: '100vw', lg: '60%' },
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '16px',
          marginX: { lg: '10px' },
          mt: { xs: '10px', lg: '20px' },
          backgroundColor: '#F28454',
          border: '1px solid #282828',
        }}
      >
        <CssBaseline />
        <CardContent>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            OfferX AI
          </Typography>
          <Typography
            component={'div'}
            gutterBottom
            sx={{
              marginTop: '20px',
              fontFamily: 'Inter, sans-serif',
              fontSize: '18px',
              fontWeight: '100',
            }}
          >
            {markDownReturn}
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: '30px',
              bottom: '10px',
              zIndex: 2000,
            }}
            aria-label='Copy to clipboard'
          >
            <CopyToClipboard
              text={queryResult}
              onCopy={() => setIsCopied(true)}
            >
              <ContentPasteIcon />
            </CopyToClipboard>
          </IconButton>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isCopied}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message='Text copied to clipboard'
      />
    </animated.div>
  );
};

export default QueryResultCard;
