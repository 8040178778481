import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNotification } from '../../context/NotificationContext';

export default function Notification() {
  const { message, open, handleExited, setOpen } = useNotification();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      key={message ? message.key : undefined}
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Center horizontally
      TransitionProps={{ onExited: handleExited }}
      onClose={handleClose}
      sx={{
        left: '50% !important',
        transform: 'translateX(-50%) !important',
        top: '93px !important',
        width: '80%', // Make it bigger
        maxWidth: '600px', // Ensure it doesn't get too large
      }}
    >
      <Alert
        onClose={handleClose}
        severity={message ? message.severity : 'info'} // Default severity to 'info'
        sx={{
          width: '100%',
          fontSize: {
            xs: '1rem', // Default font size
            lg: '1.25rem', // Larger font size for large screens
          },
          '& .MuiAlert-icon': {
            fontSize: {
              xs: 'inherit', // Default icon size
              lg: '2rem', // Larger icon size for large screens
            },
          },
        }}
      >
        {message ? message.message : ''}
      </Alert>
    </Snackbar>
  );
}
