import React from 'react';
import { Box, Typography, Backdrop, useMediaQuery } from '@mui/material';
import { useFile } from '../../context/FileContext';

const Dropzone = ({ children, style }) => {
  const { getRootProps, getInputProps, isDragActive } = useFile();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      {...getRootProps()}
      sx={{ position: 'relative', width: '100%', height: '100%', ...style }}
    >
      <input {...getInputProps()} />
      {children}
      {isDragActive && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent backdrop
          }}
          open={isDragActive}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant={isMobile ? 'h4' : 'h2'}>
              Drop the files here ...
            </Typography>
          </Box>
        </Backdrop>
      )}
    </Box>
  );
};

export default Dropzone;
