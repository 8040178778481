export const validateQuery = (query) => {
  if (query !== '') {
    return true;
  }

  return false;
};

export const hexToRgba = (hex, alpha) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getErrorMessage = (errorCode) => {
  console.log('ERROR CODE', errorCode);
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email address. Please enter a valid email.';
    case 'auth/user-disabled':
      return 'This user account has been disabled.';
    case 'auth/user-not-found':
      return 'No user found with this email.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/invalid-credential':
      return 'Incorrect credentials. Please try again.';
    case 'auth/too-many-requests':
      return 'Too many requests. Please try again in few minutes.';
    default:
      return 'An unknown error occurred. Please try again.';
  }
};
