import { Box } from '@mui/material';
import ChatBot from '../components/chatbot/ChatBot';
import Navbar from '../components/navbar/Navbar';
import Dropzone from '../components/dropzone/Dropzone';
import Footer from '../components/util/Footer';

const HomePage = () => {
  return (
    <Box flexDirection={'column'}>
      <Navbar />
      <Dropzone disabled={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: {
              lg: 'calc(100vh - 10vh - 8vh)',
            },
          }}
        >
          <ChatBot />
        </Box>
        <Footer />
      </Dropzone>
    </Box>
  );
};

export default HomePage;
