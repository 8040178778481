import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Snackbar,
  IconButton,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { animated, useSpring } from 'react-spring';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FileCard from './FileCard';

const QueryCard = (props) => {
  const query = props.query;
  const index = props.index;

  const [isCopied, setIsCopied] = React.useState(false);

  const animationProps = useSpring({
    from: { transform: 'translateY(100%)' },
    to: { transform: 'translateY(0)' },
    config: { tension: 300, friction: 20 },
  });

  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  return (
    <animated.div style={animationProps}>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Card
          sx={{
            maxWidth: { xs: '100vw', lg: '50%' },
            padding: '10px',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '16px',
            mr: { lg: '10px' },
            backgroundColor: '#FFCF00',
            border: '1px solid #282828',
            ml: 'auto', // Aligns the card to the right
            display: 'inline-block',
          }}
        >
          <CssBaseline />
          <CardContent>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              You
            </Typography>
            <FileCard index={index} />

            <Typography
              gutterBottom
              sx={{
                marginTop: '20px',
                fontFamily: 'Inter, sans-serif',
                fontSize: '18px',
                fontWeight: '100',
              }}
            >
              {query}
            </Typography>
            <IconButton
              sx={{
                position: 'fixed',
                right: '30px',
                bottom: '10px',
                zIndex: 2000,
              }}
              aria-label='Copy to clipboard'
            >
              <CopyToClipboard text={query} onCopy={() => setIsCopied(true)}>
                <ContentPasteIcon />
              </CopyToClipboard>
            </IconButton>
          </CardContent>
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isCopied}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message='Text copied to clipboard'
        />
      </Box>
    </animated.div>
  );
};

export default QueryCard;
