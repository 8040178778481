import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useSelector } from 'react-redux';

const Footer = () => {
  const queries = useSelector((state) => state.query.values);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' }, // Column on mobile, row on larger screens
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mt: { xs: 0, lg: 11 },
        px: 6,
        py: { xs: 3, lg: 0 }, // Add padding for better spacing
      }}
    >
      <Box
        display={{ xs: queries.length === 0 ? 'none' : 'flex', lg: 'none' }}
        width='100vw'
        height={{ xs: '82vh', lg: 0 }}
      />
      <Typography color='#000' variant='body2' align='center'>
        {'© '}
        {new Date().getFullYear()}
        {' OfferX AI. All rights reserved.'}
      </Typography>
      <Box
        display={{ xs: queries.length === 0 ? 'flex' : 'none', lg: 'flex' }}
        justifyContent='center'
        mt={{ xs: 3, md: 0 }}
      >
        <Typography color='#000' variant='body2' align='center'>
          {'Made with 🧡 by'}
          <Link
            target='_blank'
            href='https://www.linkedin.com/in/amruthakillada/'
            color='#222'
            sx={{ ml: 1, mr: 1, mb: 1 }}
          >
            {'Amrutha Killada'}
          </Link>
          {'&'}
          <Link
            target='_blank'
            href='https://www.linkedin.com/in/devikamujgule'
            color='#222'
            sx={{ ml: 1, mr: 1, mb: 1 }}
          >
            {'Devika Mujgule'}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
