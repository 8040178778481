import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

function NotFoundPage(props) {
    
    return (
        <Box sx={{ p: (props.loggedIn ? 10 : 0), alignItems: 'center', justifyContent: 'center' }}>
            <Typography paragraph>
                This page doesn't exist. Please navigate to correct page.
            </Typography>
        </Box>
    );
};

export default NotFoundPage;
