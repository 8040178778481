import React from 'react';
import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import GoogleIcon from '@mui/icons-material/Google';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../api/firebase';
import { getErrorMessage } from '../helpers/utils';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import authImage from '../assets/auth-side.png';

export default function SignIn() {
  const navigate = useNavigate();
  const { handleGoogleAuth, authError, setAuthError, loading, setLoading } =
    useUser();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        data.get('email'),
        data.get('password')
      );

      console.log('USER CREDENTIALS', userCredential);
      if (userCredential) navigate('/');
      setLoading(false);
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setAuthError(errorMessage);
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const res = await handleGoogleAuth();
    if (res) navigate('/');
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${authImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Box}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Container component='main' maxWidth='xs'>
          <Box
            sx={{
              xs: { mt: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
              />
              <Typography color='error'>{authError}</Typography>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign In'}
              </Button>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                sx={{ mt: 2, mb: 1 }}
                onClick={handleGoogleSignIn}
                startIcon={<GoogleIcon />}
                disabled={loading}
              >
                Sign in with Google
              </Button>
              <Grid container justifyContent='flex-end'>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Link href='/signup' variant='body2'>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Box>
              </Grid>
            </Box>
            <Typography
              variant='caption'
              sx={{ mt: 5, display: { xs: 'none', sm: 'block' } }}
            >
              Image by Makers Company
            </Typography>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
}
