import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HomePage from './pages/HomePage';
import { red, orange } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';

function ThemedApp() {
  const theme = createTheme({
    status: {
      danger: red[900],
    },
    palette: {
      secondary: {
        main: orange[500],
      },
      background: {
        default: '#fff8e1', //1E2019
      },
      // mode,
    },
    typography: {
      fontFamily: 'sans-serif ',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HomePage />
    </ThemeProvider>
  );
}

export default ThemedApp;
