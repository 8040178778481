import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Store from './app/Store';
import { Provider } from 'react-redux';
import ThemedApp from './ThemedApp';
import Skeleton from '@mui/material/Skeleton';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import NotFoundPage from './pages/NotFoundPage';
import Notification from './components/util/Notification';
import { NotificationProvider } from './context/NotificationContext';
import { UserProvider, useUser } from './context/UserContext';
import { FileProvider } from './context/FileContext';
import LoginPage from './pages/LoginPage';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';

const userRoutes = createBrowserRouter([
  {
    path: '/',
    element: <ThemedApp />,
    children: [
      {
        path: '/',
        element: <ThemedApp />,
      },
      {
        path: '/error',
        element: <NotFoundPage />,
      },
    ],
    errorElement: <NotFoundPage />,
  },
]);
const adminRoutes = createBrowserRouter([
  {
    path: '/',
    element: <ThemedApp />,
    children: [
      {
        path: '/',
        element: <ThemedApp />,
      },
      {
        path: '/error',
        element: <NotFoundPage />,
      },
    ],
    errorElement: <NotFoundPage />,
  },
]);
const notLoggedInRoutes = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    children: [
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/error',
        element: <NotFoundPage />,
      },
    ],
    errorElement: <NotFoundPage />,
  },
]);

const App = () => {
  const { userData } = useUser();
  const [router, setRouter] = useState(null);

  useEffect(() => {
    switch (userData?.role) {
      case null:
        setRouter(notLoggedInRoutes);
        break;
      case 'admin':
        setRouter(adminRoutes);
        break;
      case 'user':
        setRouter(userRoutes);
        break;
      default:
        setRouter(notLoggedInRoutes);
        break;
    }
  }, [userData?.role]);

  if (!router) {
    return <Skeleton />;
  }
  return (
    <Provider store={Store}>
      <NotificationProvider>
        <FileProvider>
          <RouterProvider router={router} fallbackElement={<Skeleton />} />
          <Notification />
        </FileProvider>
      </NotificationProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <App />
  </UserProvider>
);
