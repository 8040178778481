import React from 'react';
import { Box, Button } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useFile } from '../../context/FileContext';

const UploadFileButton = () => {
  const { open } = useFile();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={open}
        variant='text'
        color='primary'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
        }}
      >
        <AttachFileIcon sx={{ color: 'black' }} />{' '}
        {/* Use the paperclip icon */}
      </Button>
    </Box>
  );
};

export default UploadFileButton;
