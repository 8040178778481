import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import SearchBar from '../util/SearchBar';
import { useSelector } from 'react-redux';
import InfoCard from './InfoCard';
import QueryResultCard from './QueryResultCard';
import QueryCard from './QueryCard';
import StreamingResultCard from './StreamingResultCard';
import { useTheme } from '@mui/material/styles';
import { infoCards } from '../../helpers/constants';

const ChatBot = () => {
  const queryResults = useSelector((state) => state.query.results);
  const queries = useSelector((state) => state.query.values);
  const streamingResponse = useSelector(
    (state) => state.query.streamedResponse
  );
  const [isBottom, setIsBottom] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const resultContainerRef = useRef(null);

  const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent;
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      ) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
  };

  // useEffect(() => {
  //   if (resultContainerRef.current) {
  //     resultContainerRef.current.scrollTop =
  //       resultContainerRef.current.scrollHeight;
  //   }
  // }, [queries, queryResults, streamingResponse]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      padding='2%'
      sx={{ mt: '1rem' }}
    >
      <Typography
        color={'#1E2019'}
        hidden={queries.length !== 0}
        variant={isMobile ? 'h5' : 'h3'}
        gutterBottom
        fontWeight={'bold'}
        textAlign='center'
      >
        {`Understand Your Startup Offer with OfferX AI `}
      </Typography>
      <Typography
        hidden={queries.length !== 0}
        variant={isMobile ? 'body2' : 'h6'}
        gutterBottom
        fontWeight={'bold'}
        textAlign='center'
        sx={{
          color: 'rgba(30, 32, 25, 0.8)',
        }}
      >
        {`Drag and drop the document to get your questions answered`}
      </Typography>

      <Box
        sx={{
          position: isBottom ? 'fixed' : 'relative',
          bottom: isBottom ? '5%' : 'unset',
          top: isBottom ? 'unset' : '26%',
          padding: '2% 0 10px 0',
          zIndex: 1000,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SearchBar setIsBottom={setIsBottom} />
      </Box>

      <Box paddingTop='2%' hidden={queries.length !== 0} width='100%'>
        <Box
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent='center'
          alignItems='center'
          gap={{ xs: 2, lg: 10 }}
          flexWrap={'wrap'}
        >
          {Object.keys(infoCards).map((key) => (
            <InfoCard
              key={key}
              setIsBottom={setIsBottom}
              title={infoCards[key].title}
              icon={infoCards[key].icon}
              cards={infoCards[key].cards}
              isMobile={isMobileOrTablet}
              fullWidth
            />
          ))}
        </Box>
      </Box>
      <Box
        hidden={queries.length === 0}
        sx={{
          width: '80%',
          height: '80%',
          position: 'fixed',
          justifyContent: 'center',
          bottom: '12%',
          marginTop: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
          transition: 'bottom 0.5s ease-in-out',
          overflowY: 'auto',
        }}
        ref={resultContainerRef}
      >
        {queries.map((query, index) => (
          <div key={index}>
            {query !== '' ? <QueryCard query={query} index={index} /> : null}
            <br />
            {queryResults.length > 0 && queryResults[index] ? (
              <QueryResultCard queryResult={queryResults[index]} />
            ) : null}
            <br />
          </div>
        ))}
        {/* Display the streaming response */}
        <StreamingResultCard />
      </Box>
    </Box>
  );
};

export default ChatBot;
