import React, { useState, useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import mammoth from 'mammoth';
import pdfToText from 'react-pdftotext';
import { useNotification } from './NotificationContext';
import { useSelector } from 'react-redux';

export const FileContext = React.createContext(null);

export function FileProvider({ children }) {
  const { sendMessage } = useNotification();
  const queries = useSelector((state) => state.query.values);

  const [files, setFiles] = useState([]);
  const [sentFiles, setSentFiles] = useState([]);
  const [fileTexts, setFileTexts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const assistantContext = fileTexts
    ?.map((text, index) => `File ${index + 1}:\n${text}`)
    ?.join('\n\n');

  const extractTextFromPDF = (file, newFileTexts, totalFiles) => {
    pdfToText(file)
      .then((text) => {
        newFileTexts.push({ file, text });
        if (newFileTexts.length === totalFiles) {
          setFiles((prevFiles) => [...prevFiles, ...newFileTexts]);
          setFileTexts((prevFileTexts) => [
            ...prevFileTexts,
            ...newFileTexts.map((ft) => ft.text),
          ]);
          setLoading(false);
          // sendMessage('success', 'File uploaded successfully');
        }
      })
      .catch((error) => {
        setError('Failed to extract text from PDF');
        setLoading(false);
        sendMessage('error', 'Failed to extract text from PDF');
      });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoading(true);
      const newFiles = [];

      acceptedFiles.forEach((file) => {
        const extension = file.name.split('.').pop().toLowerCase();

        if (extension === 'txt') {
          const reader = new FileReader();
          reader.onload = () => {
            newFiles.push({ file, text: reader.result });
            if (newFiles.length === acceptedFiles.length) {
              setFiles((prevFiles) => [...prevFiles, ...newFiles]);
              setFileTexts((prevFileTexts) => [
                ...prevFileTexts,
                ...newFiles.map((ft) => ft.text),
              ]);
              setLoading(false);
              // sendMessage('success', 'File uploaded successfully');
            }
          };
          reader.readAsText(file);
        } else if (extension === 'pdf') {
          extractTextFromPDF(file, newFiles, acceptedFiles.length);
        } else if (extension === 'doc' || extension === 'docx') {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const { value } = await mammoth.extractRawText({
                arrayBuffer: reader.result,
              });
              newFiles.push({ file, text: value });
              if (newFiles.length === acceptedFiles.length) {
                setFiles((prevFiles) => [...prevFiles, ...newFiles]);
                setFileTexts((prevFileTexts) => [
                  ...prevFileTexts,
                  ...newFiles.map((ft) => ft.text),
                ]);
                setLoading(false);
                // sendMessage('success', 'File uploaded successfully');
              }
            } catch (error) {
              setError('Failed to extract text from DOC/DOCX');
              setLoading(false);
              sendMessage('error', 'Failed to extract text from DOC/DOCX');
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setError('Unsupported file type');
          setLoading(false);
          sendMessage('error', 'Unsupported file type');
        }
      });
    },
    [sendMessage]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: false,
    accept: {
      'text/plain': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
    },
    multiple: true,
  });

  const removeFile = (filePath) => {
    setFiles((prevFiles) =>
      prevFiles.filter((fileObj) => fileObj.file.path !== filePath)
    );
    setFileTexts((prevFileTexts) =>
      prevFileTexts.filter((_, index) => files[index].file.path !== filePath)
    );
  };

  const onSendFiles = () => {
    const newFiles = files.map((file) => ({
      file: file,
      index: queries.length,
    }));

    setSentFiles((prev) => {
      // Filter out duplicates
      const uniqueFiles = newFiles.filter(
        (newFile) =>
          !prev.some(
            (prevFile) => prevFile.file.file.name === newFile.file.file.name
          )
      );
      return [...prev, ...uniqueFiles];
    });

    setFiles([]);
    setFileTexts([]);
  };

  return (
    <FileContext.Provider
      value={{
        files,
        fileTexts,
        assistantContext,
        loading,
        error,
        getRootProps,
        getInputProps,
        isDragActive,
        open,
        removeFile,
        onSendFiles,
        sentFiles,
      }}
    >
      {children}
    </FileContext.Provider>
  );
}

export const useFile = () => {
  return useContext(FileContext);
};
